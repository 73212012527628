<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-partner menu="featuredstore"></submenu-partner> 
      
      <div class="submenu-content">
        
        <h2>Featured Partners</h2> 

        <div v-for=" item in items " :key="item.id">
          <div class="float-start" id="featured_stores">
            <router-link :to="'microsite/'+item.microsite_folder"><img
              v-if="item.image"
              :src="item.image.meta.download_url"
              :height="91"
              :width="252"
            /></router-link>
          </div>
        </div>

      </div> 
    
  </div>
</template>

<script>
import submenuPartner from '../../components/nav/submenuPartner.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from "vue"
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'FeaturedStores',
  components: { submenuPartner, BreadcrumbPage },
  setup() {
    const items = ref(null)
    const error = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Partners", link: ""})
    breadcrumb_links.value.push({text: "Featured Partners", link: "FeaturedStores"})

    var url = "/content/snippets/featuredstore/"
    getAPI.get(url)
    .then((response) => {
      items.value = response.data.items
    })
    .catch(err => {
      error.value = err
    })

    return { items, error, breadcrumb_links }

  }
}
</script>

<style scoped>
  #featured_stores {
    text-align: center;
  }
</style>